import { Vehicle } from '../vehicles/Vehicle';
import { Client } from './Client';
import { Driver } from './Driver';
import { OrderItem } from './OrderItem';

export class Order {
  id!: number;
  number!: string;
  client!: Client;
  type!: OrderType;
  status!: OrderStatus;
  orderDate!: Date;
  deliveryDate?: Date;
  comments?: string;
  items!: OrderItem[];
  vehicle?: Vehicle;
  driver?: Driver;
}

export enum OrderType {
  higiguru = 1,
  shitb = 2,
  protfilt = 3,
}

export enum OrderStatus {
  new = 1,
  assigned = 2,
  arranged = 3,
  inDelivery = 4,
  done = 5,
}
