import { OrderStatus, OrderType } from '../models/orders/Order';

export const getTypeName = (type: OrderType): string => {
  switch (type) {
    case OrderType.higiguru:
      return 'HIGIGURU';
    case OrderType.shitb:
      return 'SH-ITB';
    case OrderType.protfilt:
      return 'PROTFILT';
  }

  return '';
};

export const getStatusName = (type: OrderStatus): string => {
  switch (type) {
    case OrderStatus.arranged:
      return 'Összekészítve';
    case OrderStatus.assigned:
      return 'Hozzárendelve';
    case OrderStatus.done:
      return 'Kiszállítva';
    case OrderStatus.inDelivery:
      return 'Szállítas alatt';
    case OrderStatus.new:
      return 'Új';
  }

  return '';
};
