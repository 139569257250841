import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Delivery as DeliveryModel, DeliveryStatus } from '../../../models/delivery/Delivery';
import EditIcon from '@mui/icons-material/Edit';
import { useFormat } from '../../../utility/useFormat';
import DeleteIcon from '@mui/icons-material/Delete';
import { Order } from '../../../models/orders/Order';
import { getTypeName } from '../../../utility/orders';
import ListIcon from '@mui/icons-material/List';
import { Status } from './Status';
import { useState } from 'react';
import { AssignOrder } from './AssignOrder';
import { setActiveDeliveries } from '../../../slices/deliveriesSlice';
import { SaveDeliveryRequest } from '../../../models/delivery/SaveDeliveryRequest';
import { SaveDeliveryResponse } from '../../../models/delivery/SaveDeliveryResponse';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../slices/applicationSlice';
import { Access } from '../../../access/Access';
import { ChangeDeliveryStatus } from './ChangeDeliveryStatus';

interface Props {
  data: DeliveryModel;
  onEdit: (delivery: DeliveryModel) => void;
  onSelectOrder: (order: Order | undefined) => void;
  onDelete: (delivery: DeliveryModel) => void;
  onChangeStatus: (delivery: DeliveryModel, newStatus: DeliveryStatus) => void;
}

export const Delivery: React.FC<Props> = ({ data, onEdit, onSelectOrder, onDelete, onChangeStatus }: Props) => {
  const { formatDate, formatNumber } = useFormat();
  const dispatch = useDispatch();
  const [assignOrder, setAssignOrder] = useState<boolean>(false);
  const title = (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box>{data.vehicle ? `${data.vehicle.brand} ${data.vehicle.model} (${data.vehicle.licensePlate})` : ''}</Box>
      <Box>{formatDate(data.date)}</Box>
      <Box> {data.vehicle ? `${formatNumber(data.loadWeight)} / ${formatNumber(data.vehicle.loadWeight)} kg` : ''}</Box>
    </Box>
  );

  const overLoad = data.vehicle == null ? false : data.loadWeight > data.vehicle.loadWeight;
  const percent = data.vehicle == null ? 0 : overLoad ? 100 : (data.loadWeight / data.vehicle.loadWeight) * 100;

  const handleAssignOrder = (deliveryId: number, orders: Order[]): void => {
    const access = new Access();

    const request: SaveDeliveryRequest = {
      assignOrders: {
        id: deliveryId,
        orders: orders.map((o: Order) => o.id),
      },
    };

    access
      .saveDelivery(request)
      .then((value: SaveDeliveryResponse | undefined) => {
        if (value?.error == null) {
          dispatch(setActiveDeliveries(undefined));
          setAssignOrder(false);
          dispatch(addMessage({ message: 'Mentve', severity: 'success', key: 'save-vehicle' }));
        } else {
          dispatch(addMessage(value?.error ?? 'Hiba történt'));
        }
      })
      .catch((reason: any) => {
        dispatch(addMessage(reason.message ?? 'Hiba történt'));
      })
      .finally(() => {
        setActiveDeliveries(undefined);
      });
  };

  const handleStatusChange = (status: DeliveryStatus): void => {
    onChangeStatus(data, status);
  };

  return (
    <Card>
      <LinearProgress variant="determinate" value={percent} color={overLoad ? 'error' : 'primary'} />
      <CardHeader
        title={title}
        subheader={data.driver ? data.driver.name : ''}
        action={
          <IconButton onClick={() => onEdit(data)} sx={{ ml: 2 }}>
            <EditIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ mb: 2 }}>
            <Status data={data.status} />
          </Box>
          {data.orders.length > 0 && (
            <TableContainer component={Paper}>
              <Table size="small" aria-label="Rendelések">
                <TableHead>
                  <TableRow>
                    <TableCell>Szám / dátum</TableCell>
                    <TableCell>Cég</TableCell>
                    <TableCell>Vásárló</TableCell>
                    <TableCell>Cím</TableCell>
                    <TableCell>Tömeg&nbsp;(kg)</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.orders.map((order: Order) => (
                    <TableRow key={order.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {order.number} / {formatDate(order.orderDate)}
                      </TableCell>
                      <TableCell>{getTypeName(order.type)}</TableCell>
                      <TableCell>{order.client.name}</TableCell>
                      <TableCell>{order.client.address}</TableCell>
                      <TableCell>{order.items.reduce((sum, current) => sum + current.totalWeight, 0)}</TableCell>
                      <TableCell align="right">
                        <Button
                          startIcon={<ListIcon />}
                          color="primary"
                          size="small"
                          onClick={() => onSelectOrder(order)}
                        >
                          Infó
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {assignOrder && (
          <AssignOrder
            data={data}
            onClose={() => setAssignOrder(false)}
            onSave={handleAssignOrder}
            onSelectOrder={onSelectOrder}
          />
        )}
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Button variant="contained" type="submit" size="small" onClick={() => setAssignOrder(true)}>
            Rendelések kezelése
          </Button>
          <ChangeDeliveryStatus data={data} onSave={handleStatusChange} />
        </Box>
        <IconButton aria-label="remove delivery" title="Törlés" onClick={() => onDelete(data)}>
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};
