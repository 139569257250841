import React, { useState } from 'react';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import Alert from '@mui/material/Alert';
import { TableHeaderCell, TableHeaderProps, TableRowData, TableToolbar } from './Table';

interface Props {
  id: string;
  title?: string;
  headCells: TableHeaderCell[];
  rows: TableRowData[];
  selected: TableRowData | undefined;
  setSelected: (selected: TableRowData | undefined) => void;
  hideOthersOnSelect?: boolean;
  defaultOrderBy: string;
  defaultOrder: 'asc' | 'desc';
  hidePaper?: boolean;
  total: number;
  currentPage: number;
  currentPageLength: number;
  onChangePage?: (page: number, pageLength: number, orderBy: string, order: 'asc' | 'desc') => void;
  onChangePageLength?: (pageLength: number, orderBy: string, order: 'asc' | 'desc') => void;
  onChangeSort?: (orderBy: string, order: 'asc' | 'desc', pageLength: number) => void;
  notFoundText?: string;
}

const TableHeader: React.FC<TableHeaderProps> = ({ order, orderBy, onRequestSort, headCells }: TableHeaderProps) => {
  const createSortHandler = (property: TableHeaderCell) => (event: any) => {
    if (property.disableSorting !== true) onRequestSort(event, property.id);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: TableHeaderCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.isNumeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ width: headCell.width ?? 'auto' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell)}
              hideSortIcon={headCell.disableSorting === true}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  sx={{
                    border: 0,
                    clip: 'rect(0 0 0 0)',
                    height: 1,
                    margin: -1,
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    top: 20,
                    width: 1,
                  }}
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const TableRemote: React.FC<Props> = ({
  id,
  title,
  headCells,
  rows,
  selected,
  setSelected,
  hideOthersOnSelect,
  defaultOrderBy,
  defaultOrder,
  hidePaper,
  total,
  currentPage,
  currentPageLength,
  onChangePage,
  onChangePageLength,
  onChangeSort,
  notFoundText,
}: Props) => {
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [page, setPage] = useState(currentPage);
  const [rowsPerPage, setRowsPerPage] = useState(currentPageLength);

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);

    if (onChangeSort) onChangeSort(property, newOrder, rowsPerPage);
  };

  const handleClick = (event: any, row: TableRowData | undefined) => {
    setSelected(row?.id === selected?.id ? undefined : row);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
    if (onChangePage) onChangePage(newPage, rowsPerPage, orderBy, order);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    if (onChangePageLength) onChangePageLength(newRowsPerPage, orderBy, order);
  };

  const isSelected = (row: TableRowData) => {
    return row.id === selected?.id;
  };

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const table = (
    <>
      {title && <TableToolbar title={title} />}
      {rows.length > 0 ? (
        <>
          <TableContainer id={id}>
            <MuiTable aria-labelledby="tableTitle" size="small" aria-label={`${title} table`}>
              <TableHeader order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={headCells} />
              <TableBody>
                {
                  // stableSort(rows, getComparator(order, orderBy))
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  rows.map((row: TableRowData, index: number) => {
                    const isItemSelected = isSelected(row);

                    return !hideOthersOnSelect || (hideOthersOnSelect === true && (!selected || isItemSelected)) ? (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.rowId}
                        selected={isItemSelected}
                      >
                        {row.columns.map((col: any, colIndex: number) => {
                          return (
                            <TableCell
                              align={headCells[colIndex].isNumeric ? 'right' : 'left'}
                              key={`${row.rowId}_${uuidv4()}`}
                              sx={{ pl: 0, pr: 1, width: headCells[colIndex].width ?? 'auto' }}
                            >
                              {col.value}
                            </TableCell>
                          );
                        })}
                        {/* <TableCell component="th" scope="row" padding="normal">
                    {row.name}
                  </TableCell> */}
                      </TableRow>
                    ) : null;
                  })
                }
                {/* {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )} */}
              </TableBody>
            </MuiTable>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <Box pl={2} pr={2} pb={2}>
          <Alert severity="info">{notFoundText ?? 'Nincsenek elemek.'}</Alert>
        </Box>
      )}
    </>
  );

  return (
    <Box sx={{ width: '100%' }}>
      {hidePaper ? table : <Paper sx={{ width: '100%', marginBottom: 2 }}>{table}</Paper>}
    </Box>
  );
};
