import { Box, Typography } from '@mui/material';
import { Delivery as DeliveryModel, DeliveryStatus } from '../../../models/delivery/Delivery';
import { Delivery } from './Delivery';
import { Order } from '../../../models/orders/Order';
import { AlertDialog } from '../../common/AlertDialog';
import { useMemo, useState } from 'react';
import { Access } from '../../../access/Access';
import { useDispatch } from 'react-redux';
import { DeleteDeliveryCriteria } from '../../../models/delivery/DeleteDeliveryCriteria';
import { SaveDeliveryRequest } from '../../../models/delivery/SaveDeliveryRequest';
import { SaveDeliveryResponse } from '../../../models/delivery/SaveDeliveryResponse';
import { setActiveDeliveries } from '../../../slices/deliveriesSlice';
import { addMessage } from '../../../slices/applicationSlice';

interface Props {
  data: DeliveryModel[];
  onEdit: (delivery: DeliveryModel) => void;
  onSelectOrder: (order: Order | undefined) => void;
}

export const ActiveDeliveries: React.FC<Props> = ({ data, onEdit, onSelectOrder }: Props) => {
  const dispatch = useDispatch();
  const [deliveryToDelete, setDeliveryToDelete] = useState<DeliveryModel | undefined>(undefined);
  const [changeStatus, setChangeStatus] = useState<{ delivery: DeliveryModel; newStatus: DeliveryStatus } | undefined>(
    undefined
  );

  const deleteDelivery = () => {
    if (deliveryToDelete != null) {
      const access = new Access();
      const deleteDeliveryCriteria: DeleteDeliveryCriteria = {
        id: deliveryToDelete.id,
      };
      const request: SaveDeliveryRequest = {
        deleteDelivery: deleteDeliveryCriteria,
      };

      access
        .saveDelivery(request)
        .then((value: SaveDeliveryResponse | undefined) => {
          if (value?.error == null) {
            dispatch(setActiveDeliveries(undefined));
            dispatch(addMessage({ message: 'Törölve', severity: 'success', key: 'save-vehicle' }));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setDeliveryToDelete(undefined);
        });
    }
  };

  const isStatusChangeValid = (): boolean => {
    if (
      changeStatus != null &&
      changeStatus.delivery.orders?.length > 0 &&
      changeStatus.delivery.driver != null &&
      changeStatus.delivery.vehicle != null
    ) {
      switch (changeStatus.newStatus) {
        case DeliveryStatus.arranged: {
          return changeStatus.delivery.status === DeliveryStatus.new;
        }
        case DeliveryStatus.inDelivery: {
          return changeStatus.delivery.status === DeliveryStatus.arranged;
        }
        case DeliveryStatus.done: {
          return changeStatus.delivery.status === DeliveryStatus.inDelivery;
        }
      }
    }

    return false;
  };

  const modifyStatus = (): void => {
    if (isStatusChangeValid()) {
      const access = new Access();
      const deliveryCriteria: DeliveryModel = {
        ...changeStatus!.delivery,
        status: changeStatus!.newStatus,
      };
      const request: SaveDeliveryRequest = {
        saveDelivery: deliveryCriteria,
      };

      access
        .saveDelivery(request)
        .then((value: SaveDeliveryResponse | undefined) => {
          if (value?.error == null) {
            dispatch(setActiveDeliveries(undefined));
            dispatch(addMessage({ message: 'Törölve', severity: 'success', key: 'save-vehicle' }));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setDeliveryToDelete(undefined);
        });
    }
  };

  const handleChangeStatus = (delivery: DeliveryModel, newStatus: DeliveryStatus): void => {
    setChangeStatus({ delivery, newStatus });
  };

  const change = useMemo(() => {
    if (changeStatus == null) return undefined;
    switch (changeStatus.newStatus) {
      case DeliveryStatus.arranged: {
        return {
          title: 'Bíztosan összekészítettnek szeretné jelölni?',
          agreeText: 'Összekészítem',
        };
      }
      case DeliveryStatus.inDelivery: {
        return {
          title: 'Bíztosan elindítja a kiszállítást?',
          agreeText: 'Elindítom',
        };
      }
      case DeliveryStatus.done: {
        return {
          title: 'Bíztosan befejezettnek  szeretné jelölni?',
          agreeText: 'Befejezem',
        };
      }
    }

    return undefined;
  }, [changeStatus]);

  return (
    <>
      {data.map((delivery: DeliveryModel) => (
        <Box key={delivery.id} sx={{ mb: 3 }}>
          <Delivery
            data={delivery}
            onEdit={onEdit}
            onSelectOrder={onSelectOrder}
            onDelete={setDeliveryToDelete}
            onChangeStatus={handleChangeStatus}
          />
        </Box>
      ))}

      <AlertDialog
        title="Biztos benne?"
        agreeBtnText="Törlés"
        disagreeBtnText="Mégse"
        content={
          <Typography variant="body1">
            Ez a kiszállítás véglegesen törlésre kerül, bíztosan törölni szeretné?
          </Typography>
        }
        id="delete-delivery"
        onCancel={() => setDeliveryToDelete(undefined)}
        onAgree={deleteDelivery}
        openModal={deliveryToDelete != null}
        agreeBtnColor="error"
      />
      {change && (
        <AlertDialog
          title="Biztos benne?"
          agreeBtnText={change.agreeText}
          disagreeBtnText="Mégse"
          content={<Typography variant="body1">{change.title}</Typography>}
          id="delete-delivery"
          onCancel={() => setChangeStatus(undefined)}
          onAgree={modifyStatus}
          openModal={changeStatus != null}
          agreeBtnColor="primary"
        />
      )}
    </>
  );
};
