import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store/store';
import { DeliveriesState } from '../models/slices/DeliveriesState';
import { LoadDeliveriesResponse } from '../models/delivery/LoadDeliveriesResponse';
import { Delivery } from '../models/delivery/Delivery';
import { Listing } from '../models/Listing';

const initialState: DeliveriesState = {
  activeDeliveries: undefined,
  completedDeliveries: undefined,
};

const deliveriesSlice = createSlice({
  name: 'deliveries',
  initialState,
  reducers: {
    setResponse: (state, action: PayloadAction<LoadDeliveriesResponse | undefined>) => {
      if (action.payload?.activeDeliveries) state.activeDeliveries = action.payload.activeDeliveries;
      if (action.payload?.completedDeliveries) state.completedDeliveries = action.payload?.completedDeliveries;
    },
    setActiveDeliveries: (state, action: PayloadAction<Delivery[] | null | undefined>) => {
      state.activeDeliveries = action.payload;
    },
    setCompletedDeliveries: (state, action: PayloadAction<Listing<Delivery> | null | undefined>) => {
      state.completedDeliveries = action.payload;
    },
    // updateState: (state, action: PayloadAction<SaveDeliveryResponse>) => {
    //   if (action.payload.order) {
    //     // state.order = action.payload.order;
    //   }
    // },
  },
});

export const { setResponse, setActiveDeliveries, setCompletedDeliveries } = deliveriesSlice.actions;

export const selectActiveDeliveries = (state: RootState) => state.localData.deliveries.activeDeliveries;
export const selectCompletedDeliveries = (state: RootState) => state.localData.deliveries.completedDeliveries;

export default deliveriesSlice.reducer;
