import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Access } from '../../../access/Access';
import { PleaseWait } from '../../common/PleaseWait';
import { Alert, Box, Button, Container, IconButton, Typography } from '@mui/material';
import { Card } from '../../common/Card';
import { addMessage } from '../../../slices/applicationSlice';
import { TableHeaderCell, TableRowData } from '../../common/Table';

import RefreshIcon from '@mui/icons-material/Refresh';
import { isAdminRole } from '../../../slices/userSlice';

import ListIcon from '@mui/icons-material/List';

import AddIcon from '@mui/icons-material/Add';
import { useFormat } from '../../../utility/useFormat';
import {
  selectActiveDeliveries,
  selectCompletedDeliveries,
  setActiveDeliveries,
  setCompletedDeliveries,
  setResponse,
} from '../../../slices/deliveriesSlice';
import { Delivery, DeliveryStatus } from '../../../models/delivery/Delivery';
import { LoadDeliveriesResponse } from '../../../models/delivery/LoadDeliveriesResponse';
import { LoadDeliveriesRequest } from '../../../models/delivery/LoadDeliveriesRequest';
import { ActiveDeliveries } from './ActiveDeliveries';
import { DeliveryValues, EditDelivery } from './EditDelivery';
import { SaveDeliveryRequest } from '../../../models/delivery/SaveDeliveryRequest';
import { SaveDeliveryResponse } from '../../../models/delivery/SaveDeliveryResponse';
import { Order } from '../../../models/orders/Order';
import { Details as OrderDetails } from '../orders/Details';
import { TableRemote } from '../../common/TableRemote';
import { Status } from './Status';
import { Listing } from '../../../models/Listing';
import { DeliveryCriteria } from '../../../models/delivery/DeliveryCriteria';
import { Details } from './Details';

const defaultCriteria: DeliveryCriteria = {
  page: 0,
  pageSize: 25,
  sortBy: 'date',
  sortDirection: 'asc',
};

export const Deliveries: React.FC = () => {
  const dispatch = useDispatch();
  const { formatDate, formatNumber } = useFormat();
  const [isLoadingActive, setIsLoadingActive] = useState<boolean>(false);
  const [isLoadingCompleted, setIsLoadingCompleted] = useState<boolean>(false);
  const isAdmin = useSelector(isAdminRole);
  const activeDeliveries: Delivery[] | undefined | null = useSelector(selectActiveDeliveries);
  const completedDeliveries: Listing<Delivery> | undefined | null = useSelector(selectCompletedDeliveries);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState<Order | undefined>(undefined);

  const [selectedDelivery, setSelectedDelivery] = useState<Delivery | undefined>(undefined);

  const [criteria, setCriteria] = useState<DeliveryCriteria>(defaultCriteria);

  const [selectedDeliveryDetails, setSelectedDeliveryDetails] = useState<Delivery | undefined>(undefined);

  useEffect(() => {
    if (activeDeliveries === undefined && !isLoadingActive) {
      setIsLoadingActive(true);
      dispatch(setActiveDeliveries(null));

      const request: LoadDeliveriesRequest = { getActiveDeliveries: {} };

      const access = new Access();
      access
        .loadDeliveries(request)
        .then((value: LoadDeliveriesResponse | undefined) => {
          if (value?.error == null) {
            dispatch(setResponse(value));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoadingActive(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDeliveries]);

  useEffect(() => {
    if (completedDeliveries === undefined && !isLoadingActive) {
      setIsLoadingCompleted(true);
      dispatch(setCompletedDeliveries(null));

      const request: LoadDeliveriesRequest = {
        getCompletedDeliveries: criteria,
      };

      const access = new Access();
      access
        .loadDeliveries(request)
        .then((value: LoadDeliveriesResponse | undefined) => {
          if (value?.error == null) {
            dispatch(setResponse(value));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoadingCompleted(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedDeliveries]);

  useEffect(() => {
    if (completedDeliveries != null && !isLoadingCompleted) {
      const request: LoadDeliveriesRequest = {
        getCompletedDeliveries: criteria,
      };

      const access = new Access();
      access
        .loadDeliveries(request)
        .then((value: LoadDeliveriesResponse | undefined) => {
          if (value?.error == null) {
            dispatch(setResponse(value));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setIsLoadingCompleted(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria]);

  const headCells: TableHeaderCell[] = [
    {
      id: 'date',
      isNumeric: false,
      label: 'Dátum',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'driver',
      isNumeric: false,
      label: 'Sofőr',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'vehicle',
      isNumeric: false,
      label: 'Jármű',
      disablePadding: true,
      width: '25%',
    },
    {
      id: 'status',
      isNumeric: false,
      label: 'Státus',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'load',
      isNumeric: false,
      label: 'Terhelés (kg)',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'operation',
      isNumeric: false,
      label: '',
      disablePadding: true,
      width: '10%',
    },
  ];

  const handleCreateDelivery = (): void => {
    setSelectedDelivery({
      id: 0,
      date: new Date(),
      driver: undefined,
      loadWeight: 0,
      orders: [],
      status: DeliveryStatus.new,
      vehicle: undefined,
    });
  };

  const saveDelivery = (values: DeliveryValues): void => {
    if (values.vehicleId && values.driverId) {
      const access = new Access();
      const date = formatDate(values.date, 'yyyy-MM-DD');

      const deliveryCriteria: Delivery = {
        id: values.id,
        date: date as any,
        loadWeight: 0,
        orders: [],
        status: values.status,
        driver: {
          id: parseInt(values.driverId),
          name: '',
        },
        vehicle: {
          id: parseInt(values.vehicleId),
          active: true,
          brand: '',
          licensePlate: '',
          loadWeight: 0,
          model: '',
        },
      };
      const request: SaveDeliveryRequest = {
        saveDelivery: deliveryCriteria,
      };

      access
        .saveDelivery(request)
        .then((value: SaveDeliveryResponse | undefined) => {
          if (value?.error == null) {
            dispatch(setActiveDeliveries(undefined));
            setSelectedDelivery(undefined);
            dispatch(addMessage({ message: 'Mentve', severity: 'success', key: 'save-vehicle' }));
          } else {
            dispatch(addMessage(value?.error ?? 'Hiba történt'));
          }
        })
        .catch((reason: any) => {
          dispatch(addMessage(reason.message ?? 'Hiba történt'));
        })
        .finally(() => {
          setActiveDeliveries(undefined);
        });
    }
  };
  const handleEditDelivery = (delivery: Delivery): void => setSelectedDelivery(delivery);

  const refreshList = () => dispatch(setActiveDeliveries(undefined));

  const completedDeliveriesData: TableRowData[] = useMemo(
    () => {
      return (completedDeliveries?.items ?? []).map((delivery: Delivery) => ({
        rowId: `completed-delivery-${delivery.id}`,
        id: delivery.id,
        columns: [
          {
            id: 'date',
            cValue: delivery.date,
            value: formatDate(delivery.date),
          },
          {
            id: 'driver',
            cValue: delivery.driver?.name,
            value: delivery.driver?.name,
          },
          {
            id: 'vehicle',
            cValue: delivery.vehicle
              ? `${delivery.vehicle.brand} ${delivery.vehicle.model} (${delivery.vehicle.licensePlate})`
              : '',
            value: delivery.vehicle
              ? `${delivery.vehicle.brand} ${delivery.vehicle.model} (${delivery.vehicle.licensePlate})`
              : '',
          },
          {
            id: 'status',
            cValue: delivery.status,
            value: <Status data={delivery.status} />,
          },
          {
            id: 'load',
            cValue: delivery.loadWeight,
            value: delivery.vehicle
              ? `${formatNumber(delivery.loadWeight)} / ${formatNumber(delivery.vehicle.loadWeight)}`
              : '',
          },
          {
            id: 'operation',
            cValue: '',
            value: (
              <>
                <Button
                  startIcon={<ListIcon />}
                  color="primary"
                  size="small"
                  onClick={() => setSelectedDeliveryDetails(delivery)}
                >
                  Infó
                </Button>
              </>
            ),
          },
        ],
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [completedDeliveries]
  );

  return (
    <Container maxWidth="xl">
      <Card sx={{ minHeight: '80vh' }}>
        {isLoadingActive || isAdmin == null ? (
          <PleaseWait />
        ) : (
          <>
            {activeDeliveries === undefined ? (
              <Alert severity="warning">Nincenek kiszállítások</Alert>
            ) : (
              <>
                <Box
                  sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
                >
                  <Button
                    variant="contained"
                    sx={{ my: 2 }}
                    startIcon={<AddIcon sx={{ fill: '#fff' }} />}
                    color="primary"
                    onClick={handleCreateDelivery}
                  >
                    Új kiszállítás
                  </Button>
                  <IconButton sx={{ my: 2 }} color="primary" onClick={refreshList} title="Frissítés">
                    <RefreshIcon />
                  </IconButton>
                </Box>
                {activeDeliveries != null && (
                  <ActiveDeliveries
                    data={activeDeliveries!}
                    onEdit={handleEditDelivery}
                    onSelectOrder={setSelectedOrderDetails}
                  />
                )}

                <Box sx={{ mt: 4, mb: 3 }}>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Előző kiszállítások
                  </Typography>
                  <TableRemote
                    id="closedDeliveries"
                    headCells={headCells}
                    rows={completedDeliveriesData}
                    hideOthersOnSelect={true}
                    selected={undefined}
                    setSelected={() => {}}
                    hidePaper
                    defaultOrderBy={completedDeliveries?.sortBy ?? ''}
                    defaultOrder={completedDeliveries?.sortDirection ?? 'desc'}
                    total={completedDeliveries?.total ?? 0}
                    currentPage={completedDeliveries?.page ?? 0}
                    currentPageLength={completedDeliveries?.pageSize ?? 0}
                    notFoundText="Nincsenek előző kiszállítások."
                    onChangeSort={(orderBy: string, order: 'asc' | 'desc', pageLength: number) =>
                      setCriteria({ ...criteria, sortBy: orderBy, sortDirection: order, pageSize: pageLength })
                    }
                    onChangePage={(page: number, pageLength: number, orderBy: string, order: 'asc' | 'desc') =>
                      setCriteria({
                        ...criteria,
                        page,
                        sortBy: orderBy,
                        sortDirection: order,
                        pageSize: pageLength,
                      })
                    }
                    onChangePageLength={(pageLength: number, orderBy: string, order: 'asc' | 'desc') =>
                      setCriteria({ ...criteria, sortBy: orderBy, sortDirection: order, pageSize: pageLength })
                    }
                  />
                </Box>

                {selectedDeliveryDetails && (
                  <Details
                    data={selectedDeliveryDetails}
                    onClose={() => setSelectedDeliveryDetails(undefined)}
                    onSelectOrder={setSelectedOrderDetails}
                  />
                )}

                {selectedDelivery && (
                  <EditDelivery
                    data={selectedDelivery}
                    onClose={() => setSelectedDelivery(undefined)}
                    onSave={saveDelivery}
                  />
                )}

                {selectedOrderDetails && (
                  <OrderDetails data={selectedOrderDetails} onClose={() => setSelectedOrderDetails(undefined)} />
                )}
              </>
            )}
          </>
        )}
      </Card>
    </Container>
  );
};
