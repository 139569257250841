import { Box, Button, Grid, SwipeableDrawer, Typography } from '@mui/material';

import { useMemo } from 'react';
import { Table, TableHeaderCell } from '../../common/Table';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';

import { useFormat } from '../../../utility/useFormat';
import { Delivery } from '../../../models/delivery/Delivery';
import { Order } from '../../../models/orders/Order';
import { getTypeName } from '../../../utility/orders';
import ListIcon from '@mui/icons-material/List';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

interface Props {
  data: Delivery;
  onClose: () => void;
  onSelectOrder: (order: Order | undefined) => void;
}

export const Details: React.FC<Props> = ({ data, onClose, onSelectOrder }: Props) => {
  const { formatDate } = useFormat();
  const headCells: TableHeaderCell[] = [
    {
      id: 'number',
      isNumeric: false,
      label: 'Szám / dátum',
      disablePadding: true,
      width: '15%',
    },
    {
      id: 'type',
      isNumeric: false,
      label: 'Cég',
      disablePadding: true,
      width: '20%',
    },
    {
      id: 'client',
      isNumeric: false,
      label: 'Vásárló',
      disablePadding: true,
      width: '25%',
    },
    {
      id: 'address',
      isNumeric: false,
      label: 'Cím',
      disablePadding: true,
      width: '30%',
    },
    {
      id: 'operation',
      isNumeric: false,
      label: '',
      disablePadding: true,
      width: '10%',
    },
  ];

  const ordersData = useMemo(() => {
    return data.orders.map((order: Order) => ({
      rowId: `delivery-order-${order.id}`,
      id: order.id,
      data: order,
      columns: [
        {
          id: 'number',
          cValue: order.number,
          value: (
            <>
              {order.number} / {formatDate(order.orderDate)}
            </>
          ),
        },
        {
          id: 'type',
          cValue: order.type,
          value: getTypeName(order.type),
        },
        {
          id: 'client',
          cValue: order.client.name,
          value: order.client.name,
        },
        {
          id: 'address',
          cValue: order.client.address,
          value: <>{order.client.address}</>,
        },
        {
          id: 'operation',
          cValue: '',
          value: (
            <>
              <Button startIcon={<ListIcon />} color="primary" size="small" onClick={() => onSelectOrder(order)}>
                Infó
              </Button>
            </>
          ),
        },
      ],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.orders]);

  return (
    <SwipeableDrawer anchor="right" open onClose={onClose} onOpen={onClose}>
      <Box sx={{ width: { xs: '95vw', sm: 600 }, p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="h4">Kiszállítás</Typography>
            <Typography variant="body1">{formatDate(data.date)}</Typography>
          </Box>
        </Box>
        <Box sx={{ pt: 4 }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <ReceiptLongIcon sx={{ mr: 1 }} />
            Rendelések
          </Typography>
          <Table
            id="delivery-orders"
            headCells={headCells}
            rows={ordersData}
            hideOthersOnSelect={true}
            selected={undefined}
            setSelected={() => {}}
            hidePaper
            defaultPagination={{ page: 0, rowsPerPage: 25, order: 'desc', orderBy: 'number' }}
          />
        </Box>
        <Box sx={{ pt: 4 }}>
          <Grid container sx={{ mt: 1 }}>
            {data.vehicle && (
              <Grid item xs={data.driver ? 6 : 12}>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                  <LocalShippingIcon sx={{ mr: 1 }} />
                  Jármű
                </Typography>
                <Typography variant="body1">
                  {data.vehicle.brand} {data.vehicle.model}
                </Typography>
                <Typography variant="body1">{data.vehicle.licensePlate}</Typography>
              </Grid>
            )}
            {data.driver && (
              <Grid item xs={data.vehicle ? 6 : 12}>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                  <PersonIcon sx={{ mr: 1 }} />
                  Sofőr
                </Typography>
                <Typography variant="body1">{data.driver.name}</Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
