import { Button } from '@mui/material';
import { Delivery, DeliveryStatus } from '../../../models/delivery/Delivery';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DoneIcon from '@mui/icons-material/Done';

interface Props {
  data: Delivery;
  onSave: (status: DeliveryStatus) => void;
}

export const ChangeDeliveryStatus: React.FC<Props> = ({ data, onSave }: Props) => {
  if (data.orders?.length > 0 && data.driver != null && data.vehicle != null) {
    switch (data.status) {
      case DeliveryStatus.new: {
        return (
          <Button
            onClick={() => onSave(DeliveryStatus.arranged)}
            variant="contained"
            size="small"
            sx={{ ml: 2 }}
            startIcon={<InventoryIcon sx={{ fill: '#fff', backgroundColor: 'transparent !important' }} />}
          >
            Összekészítve
          </Button>
        );
      }
      case DeliveryStatus.arranged: {
        return (
          <Button
            onClick={() => onSave(DeliveryStatus.inDelivery)}
            variant="contained"
            size="small"
            sx={{ ml: 2 }}
            startIcon={<LocalShippingIcon sx={{ fill: '#fff', backgroundColor: 'transparent !important' }} />}
          >
            Kiszállítás megkezdése
          </Button>
        );
      }
      case DeliveryStatus.inDelivery: {
        return (
          <Button
            onClick={() => onSave(DeliveryStatus.done)}
            variant="contained"
            size="small"
            sx={{ ml: 2 }}
            startIcon={<DoneIcon sx={{ fill: '#fff', backgroundColor: 'transparent !important' }} />}
          >
            Kiszállítás befejezése
          </Button>
        );
      }
    }
  }
  return null;
};
